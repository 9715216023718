var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "config-container" },
    [
      _c("div", { staticClass: "config-title" }, [
        _vm._v(_vm._s(_vm.translateTitle("基础配置"))),
      ]),
      _c("div", { staticClass: "config-info" }, [
        _vm._v(
          " " +
            _vm._s(_vm.translateTitle("社区助手的名称、类型、图片等配置")) +
            " "
        ),
      ]),
      _c("Form", {
        attrs: { column: _vm.column, query: _vm.query },
        on: {
          handleCopy: _vm.handleCopy,
          handleImageUrl: _vm.handleImageUrl,
          handleReset: _vm.handleReset,
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c("el-divider"),
                _c("div", { staticClass: "config-title" }, [
                  _vm._v(_vm._s(_vm.translateTitle("自动回复配置"))),
                ]),
                _c("div", { staticClass: "config-info" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.translateTitle("社区助手内自动回复游客配置")) +
                      " "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "button",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { loading: _vm.loading, type: "primary" },
                    on: { click: _vm.onSave },
                  },
                  [_vm._v(" " + _vm._s(_vm.translateTitle("保存")) + " ")]
                ),
                _c("el-divider"),
              ]
            },
            proxy: true,
          },
          {
            key: "menu",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    staticClass: "m-b-10",
                    attrs: { size: "medium", type: "primary" },
                    on: { click: _vm.handleAddMenu },
                  },
                  [_vm._v(" " + _vm._s(_vm.translateTitle("新增菜单")) + " ")]
                ),
                _vm._l(_vm.query.menu, function (item, i) {
                  return _c(
                    "div",
                    { key: i },
                    [
                      _c(
                        "el-row",
                        { attrs: { type: "flex" } },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "m-r-10 m-b-10",
                              attrs: { span: 4 },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  clearable: "",
                                  placeholder:
                                    _vm.translateTitle("请输入菜单名称"),
                                },
                                model: {
                                  value: item.name,
                                  callback: function ($$v) {
                                    _vm.$set(item, "name", $$v)
                                  },
                                  expression: "item.name",
                                },
                              }),
                            ],
                            1
                          ),
                          !item.child.length
                            ? _c(
                                "el-col",
                                {
                                  staticClass: "m-r-10 m-b-10",
                                  attrs: { span: 8 },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder:
                                        _vm.translateTitle(
                                          "请输入配置跳转链接"
                                        ),
                                    },
                                    model: {
                                      value: item.link,
                                      callback: function ($$v) {
                                        _vm.$set(item, "link", $$v)
                                      },
                                      expression: "item.link",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleAddChildMenu(item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.translateTitle("新建子菜单")) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.hanldeRemove(i)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.translateTitle("删除")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._l(item.child, function (el, idx) {
                        return _c(
                          "el-row",
                          { key: idx, attrs: { type: "flex" } },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "m-r-10 m-b-10",
                                attrs: { span: 4 },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder:
                                      _vm.translateTitle("请输入子菜单名称"),
                                  },
                                  model: {
                                    value: el.name,
                                    callback: function ($$v) {
                                      _vm.$set(el, "name", $$v)
                                    },
                                    expression: "el.name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "m-r-10 m-b-10",
                                attrs: { span: 8 },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder:
                                      _vm.translateTitle("请输入配置跳转链接"),
                                  },
                                  model: {
                                    value: el.link,
                                    callback: function ($$v) {
                                      _vm.$set(el, "link", $$v)
                                    },
                                    expression: "el.link",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { disabled: idx == 0, type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleUpDown(
                                          "上移",
                                          el,
                                          i,
                                          idx
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.translateTitle("上移")) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      disabled:
                                        item.child.length == 1 ||
                                        item.child.length - 1 == idx,
                                      type: "text",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleUpDown(
                                          "下移",
                                          el,
                                          i,
                                          idx
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.translateTitle("下移")) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.hanldeRemove(i, idx)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.translateTitle("删除")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("el-divider"),
      _c(
        "div",
        { staticClass: "config-btn" },
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: { click: _vm.onSaveAll },
            },
            [_vm._v(" " + _vm._s(_vm.translateTitle("保存")) + " ")]
          ),
          _c(
            "el-button",
            { attrs: { loading: _vm.loading }, on: { click: _vm.cancel } },
            [_vm._v(" " + _vm._s(_vm.translateTitle("取消")) + " ")]
          ),
        ],
        1
      ),
      _c("div"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }