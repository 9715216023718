<template>
  <div class="list-container">
    <div class="list-details">
      <div class="list-name">
        <div class="flex">
          <el-image :preview-src-list="[query.pic]" :src="query.pic" />
          <span>{{ query.name }}</span>
        </div>
        <el-button type="primary">新建助手消息</el-button>
      </div>
      <div class="list-info">
        群发总次数：{{ query.send_num }} 群发总条数：{{
          query.send_content_num
        }}
      </div>
    </div>
    <!-- 表格 -->
    <TablePaging
      :column-list="columnList"
      :is-pagination="false"
      :loading="loading"
      :max-height="maxHeight"
      :page-info="pageInfo"
      :table-data="list"
      @handleCurrentChange="handleCurrentChange"
    >
      <!-- 标题 -->
      <template #content="{ row }">
        <span v-if="row.is_pic == 0" v-html="row.content"></span>
        <el-image
          v-if="row.is_pic == 1"
          :preview-src-list="[row.content]"
          :src="row.content"
        />
      </template>
    </TablePaging>
  </div>
</template>

<script>
  import { getAssistantDetail, getAssistantMsgList } from '@/api/operate'
  import TablePaging from '@/components/Table'
  import tableMixins from '@/mixins/tableCommon.vue'
  export default {
    components: {
      TablePaging,
    },
    mixins: [tableMixins],
    data() {
      return {
        query: {
          name: null,
          pic: null,
          secret: null,
          des: null,
          type: null,
          send_num: null,
          send_content_num: null,
          menu: [],
        },
        // 表格最大高度
        maxHeight: document.body.clientHeight - 340,
        columnList: [
          { label: '标题', slotName: 'content' },
          { label: '类型', prop: 'content_type_str' },
          { label: '状态', prop: 'send_type_str' },
          { label: '群发时间', prop: 'create_time_str' },
          { label: '发布者', prop: 'send_name' },
          {
            label: '操作',
            isButton: true,
            btnList: [
              {
                label: '复制',
                type: 'primary',
                eventName: 'handleCopy',
              },
              {
                label: '编辑',
                type: 'primary',
                eventName: 'handleEdit',
                showName: 'send_type',
                showStatusList: [3],
              },
              {
                label: '删除',
                type: 'danger',
                eventName: 'handleRemove',
              },
            ],
          },
        ],
      }
    },
    created() {
      this.getAssistantDetail()
      this.getAssistantMsgList()
    },
    methods: {
      // 获取详情
      async getAssistantDetail() {
        const params = { id: this.$route.query.id }
        const res = await getAssistantDetail(params)
        if (res.success) {
          for (let key of Object.keys(this.query)) {
            this.query[key] = res.data[key]
          }
        }
      },
      // 获取社区助手消息列表
      async getAssistantMsgList() {
        const params = { assistant_id: this.$route.query.id, step: 20 }
        const res = await getAssistantMsgList(params)
        if (res.success) {
          this.list = res.list
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.list';
  #{$base}-container {
    min-height: calc(100vh - 60px - 50px - 20px * 2 - 65px);
    padding: 20px;
    background: #fff;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s,
      color 0.1s, font-size 0s;
    #{$base}-title {
      font-size: 15px;
      font-weight: bold;
      margin: 5px 0;
    }
    #{$base}-info {
      font-size: 13px;
      color: $base-color-text-secondary;
      padding: 10px;
      border-top: 1px solid #dcdfe6;
    }
    #{$base}-details {
      border: 1px solid #dcdfe6;
      margin-bottom: 15px;
    }
    #{$base}-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      .flex {
        display: flex;
        align-items: center;
        span {
          font-size: 15px;
          font-weight: bold;
          margin-left: 10px;
        }
      }
      .el-image {
        width: 50px;
      }
      .el-button {
        height: 32px;
      }
    }
    #{$base}-btn {
      margin: 40px 0 80px 110px;
    }
    .m-r-10 {
      margin-right: 10px;
    }
    .m-b-10 {
      margin-bottom: 10px;
    }
  }
</style>
