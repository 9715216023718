var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-container" },
    [
      _c("div", { staticClass: "list-details" }, [
        _c(
          "div",
          { staticClass: "list-name" },
          [
            _c(
              "div",
              { staticClass: "flex" },
              [
                _c("el-image", {
                  attrs: {
                    "preview-src-list": [_vm.query.pic],
                    src: _vm.query.pic,
                  },
                }),
                _c("span", [_vm._v(_vm._s(_vm.query.name))]),
              ],
              1
            ),
            _c("el-button", { attrs: { type: "primary" } }, [
              _vm._v("新建助手消息"),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "list-info" }, [
          _vm._v(
            " 群发总次数：" +
              _vm._s(_vm.query.send_num) +
              " 群发总条数：" +
              _vm._s(_vm.query.send_content_num) +
              " "
          ),
        ]),
      ]),
      _c("TablePaging", {
        attrs: {
          "column-list": _vm.columnList,
          "is-pagination": false,
          loading: _vm.loading,
          "max-height": _vm.maxHeight,
          "page-info": _vm.pageInfo,
          "table-data": _vm.list,
        },
        on: { handleCurrentChange: _vm.handleCurrentChange },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function (ref) {
              var row = ref.row
              return [
                row.is_pic == 0
                  ? _c("span", { domProps: { innerHTML: _vm._s(row.content) } })
                  : _vm._e(),
                row.is_pic == 1
                  ? _c("el-image", {
                      attrs: {
                        "preview-src-list": [row.content],
                        src: row.content,
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }