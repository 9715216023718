<template>
  <div class="config-container">
    <div class="config-title">{{ translateTitle('基础配置') }}</div>
    <div class="config-info">
      {{ translateTitle('社区助手的名称、类型、图片等配置') }}
    </div>
    <Form
      :column="column"
      :query="query"
      @handleCopy="handleCopy"
      @handleImageUrl="handleImageUrl"
      @handleReset="handleReset"
    >
      <template #title>
        <el-divider />
        <div class="config-title">{{ translateTitle('自动回复配置') }}</div>
        <div class="config-info">
          {{ translateTitle('社区助手内自动回复游客配置') }}
        </div>
      </template>
      <template #button>
        <el-button :loading="loading" type="primary" @click="onSave">
          {{ translateTitle('保存') }}
        </el-button>
        <el-divider />
      </template>
      <template #menu>
        <el-button
          class="m-b-10"
          size="medium"
          type="primary"
          @click="handleAddMenu"
        >
          {{ translateTitle('新增菜单') }}
        </el-button>
        <div v-for="(item, i) in query.menu" :key="i">
          <el-row type="flex">
            <el-col class="m-r-10 m-b-10" :span="4">
              <el-input
                v-model="item.name"
                clearable
                :placeholder="translateTitle('请输入菜单名称')"
              />
            </el-col>
            <el-col v-if="!item.child.length" class="m-r-10 m-b-10" :span="8">
              <el-input
                v-model="item.link"
                clearable
                :placeholder="translateTitle('请输入配置跳转链接')"
              />
            </el-col>
            <el-col :span="6">
              <el-button type="text" @click="handleAddChildMenu(item)">
                {{ translateTitle('新建子菜单') }}
              </el-button>
              <el-button type="text" @click="hanldeRemove(i)">
                {{ translateTitle('删除') }}
              </el-button>
            </el-col>
          </el-row>
          <el-row v-for="(el, idx) in item.child" :key="idx" type="flex">
            <el-col class="m-r-10 m-b-10" :span="4">
              <el-input
                v-model="el.name"
                clearable
                :placeholder="translateTitle('请输入子菜单名称')"
              />
            </el-col>
            <el-col class="m-r-10 m-b-10" :span="8">
              <el-input
                v-model="el.link"
                clearable
                :placeholder="translateTitle('请输入配置跳转链接')"
              />
            </el-col>
            <el-col :span="6">
              <el-button
                :disabled="idx == 0"
                type="text"
                @click="handleUpDown('上移', el, i, idx)"
              >
                {{ translateTitle('上移') }}
              </el-button>
              <el-button
                :disabled="
                  item.child.length == 1 || item.child.length - 1 == idx
                "
                type="text"
                @click="handleUpDown('下移', el, i, idx)"
              >
                {{ translateTitle('下移') }}
              </el-button>
              <el-button type="text" @click="hanldeRemove(i, idx)">
                {{ translateTitle('删除') }}
              </el-button>
            </el-col>
          </el-row>
        </div>
      </template>
    </Form>
    <el-divider />
    <div class="config-btn">
      <el-button :loading="loading" type="primary" @click="onSaveAll">
        {{ translateTitle('保存') }}
      </el-button>
      <el-button :loading="loading" @click="cancel">
        {{ translateTitle('取消') }}
      </el-button>
    </div>
    <div></div>
  </div>
</template>

<script>
  import Form from '@/components/Form'
  import clip from '@/utils/clipboard'
  import { mapGetters, mapActions } from 'vuex'
  import { saveLocal } from '@/api/configure'
  import { getAssistantDetail, assistantSave, resetSecrte } from '@/api/operate'
  import { translateTitle } from '@/utils/i18n'
  export default {
    components: {
      Form,
    },
    data() {
      return {
        loading: false,
        query: {
          name: null,
          global_is_open_score_day: null,
          pic: null,
          global_is_open_message: null,
          secret: null,
          des: null,
          type: null,
          menu: [],
          assistant_config_visitor_msg_switch: null,
          assistant_config_visitor_msg: null,
          assistant_config_login_msg_switch: null,
          assistant_config_login_msg: null,
        },
        type: {
          1: '系统助手',
          2: '自定义助手',
          3: '交友助手',
          4: '新悦购助手',
          5: '钱包/积分',
          6: '群通知',
        },
        keys: ['name', 'pic', 'secret', 'des', 'type', 'menu'],
        // 是否
        whetherList: Object.freeze([
          {
            id: '1',
            label: '开启',
          },
          {
            id: '-1',
            label: '关闭',
          },
        ]),
      }
    },
    computed: {
      ...mapGetters({
        config: 'user/config',
      }),
      column() {
        return [
          {
            label: '名称',
            prop: 'name',
          },
          {
            label: '助手类型',
            type: 'text',
            text: this.type[this.query.type],
          },
          {
            label: '积分日报',
            prop: 'global_is_open_score_day',
            type: 'radio',
            list: this.whetherList,
            isHide: this.query.type != 5,
          },
          {
            label: '上传图片',
            prop: 'pic',
            type: 'uploadImage',
            width: 80,
            height: 80,
            eventName: 'handleImageUrl',
            tips: '图片建议尺寸：120x120px',
          },
          {
            label: '用户留言',
            prop: 'global_is_open_message',
            type: 'radio',
            list: this.whetherList,
            isHide: this.query.type != 1,
          },
          {
            label: 'secret',
            prop: 'secret',
            clickText: '重置',
            clickEventName: 'handleReset',
            tipsBtn: '复制',
            tipsEventName: 'handleCopy',
            isHide: this.query.type != 2,
            tips: 'secret用于供第三方开发接入模板消息使用',
          },
          {
            label: '简单描述',
            prop: 'des',
            type: 'textarea',
          },
          {
            type: 'slot',
            slotName: 'title',
            labelWidth: '0',
            isHide: this.query.type != 1,
          },
          {
            label: '游客消息',
            prop: 'assistant_config_visitor_msg_switch',
            type: 'radio',
            list: this.whetherList,
            isHide: this.query.type != 1,
          },
          {
            label: '设置游客消息',
            prop: 'assistant_config_visitor_msg',
            type: 'textarea',
            isHide: this.query.assistant_config_visitor_msg_switch != 1,
            tips: '<p>示例：欢迎来到xx客户端，祝你在这里玩得愉快！</p><p>&lt;a href=&quot;magapp://useredit?needlogin=1&quot;&gt;立即登录&gt;&lt;/a&gt;</p><p>支持添加链接跳转，支持换行，可自主编辑。</p>',
          },
          {
            label: '登录问候语',
            prop: 'assistant_config_login_msg_switch',
            type: 'radio',
            list: this.whetherList,
            isHide: this.query.type != 1,
          },
          {
            label: '问候语',
            prop: 'assistant_config_login_msg',
            type: 'textarea',
            isHide: this.query.assistant_config_login_msg_switch != 1,
            tips: '<p>示例：欢迎来到xx客户端，祝你在这里玩得愉快！</p><p>&lt;a href=&quot;magapp://useredit?needlogin=1&quot;&gt;立即登录&gt;&lt;/a&gt;</p><p>支持添加链接跳转，支持换行，可自主编辑。</p>',
          },
          {
            type: 'slot',
            slotName: 'button',
            labelWidth: '0',
            isHide: this.query.type != 1,
          },
          {
            type: 'slot',
            slotName: 'menu',
            label: '自定义菜单',
            tips: '菜单名称建议不超过4个汉字或8个字母，配置的模板消息请先新建后保存草稿',
          },
        ]
      },
    },
    mounted() {
      if (this.config) {
        for (let key of Object.keys(this.query)) {
          if (!this.keys.includes(key)) {
            this.query[key] = this.config[key]
          }
        }
      }
      this.getAssistantDetail()
    },
    methods: {
      translateTitle,
      ...mapActions({
        delVisitedRoute: 'tabs/delVisitedRoute',
      }),
      // 获取详情
      async getAssistantDetail() {
        const params = { id: this.$route.query.id }
        const res = await getAssistantDetail(params)
        if (res.success) {
          for (let key of Object.keys(this.query)) {
            if (this.keys.includes(key)) {
              this.query[key] = res.data[key]
            }
          }
        }
      },
      // 复制
      handleCopy(query, e) {
        clip(query.secret, e)
      },
      // 获取图片上传地址
      handleImageUrl(prop, id) {
        this.query[prop] = id
      },
      onSaveAll() {
        this.onSave(true)
        this.onSubmit()
      },
      // 保存本地config
      async onSave(flag) {
        const params = {}
        for (let key of Object.keys(this.query)) {
          if (!this.keys.includes(key)) {
            params[key] = this.query[key]
          }
        }
        const res = await saveLocal(params)
        if (res.success) {
          await this.$store.dispatch('user/getConfigs')
          if (!flag) this.$message.success('保存成功')
        } else {
          this.loading = false
          this.$message.error('保存失败')
        }
      },
      async onSubmit() {
        if (this.loading) return
        this.loading = true
        const params = {}
        for (let key of Object.keys(this.query)) {
          if (this.keys.includes(key)) {
            if (key !== 'menu') {
              params[key] = JSON.parse(JSON.stringify(this.query[key]))
            } else {
              params[key] = JSON.stringify(this.query[key])
            }
          }
        }
        params.id = this.$route.query.id
        const res = await assistantSave(params)
        if (res.success) {
          this.loading = false
          this.$message.success('保存成功')
          this.cancel()
        } else {
          this.loading = false
          this.$message.error('保存失败')
        }
      },
      // 新增菜单
      handleAddMenu() {
        console.log(this.query)
        this.query.menu.push({
          name: null,
          link: null,
          child: [],
        })
      },
      // 新增子菜单
      handleAddChildMenu(val) {
        console.log(val)
        val.child.push({
          name: null,
          link: null,
        })
      },
      // 重置
      handleReset() {
        this.$confirm(
          `此操作将重置secret，之前使用的旧secret接口将无法正常使用, 是否继续?`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
          .then(async () => {
            const params = { id: this.$route.query.id }
            const res = await resetSecrte(params)
            if (res.success) {
              this.query.secret = res.data
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            })
          })
      },
      // 删除
      hanldeRemove(firstIdx, secondIdx) {
        if (!secondIdx && secondIdx != 0) {
          if (this.query.menu[firstIdx].child.length) {
            this.$confirm(`此操作将删除其子菜单, 是否继续?`, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                this.query.menu.splice(firstIdx, 1)
              })
              .catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消',
                })
              })
          } else {
            this.query.menu.splice(firstIdx, 1)
          }
        } else {
          this.query.menu[firstIdx].child.splice(secondIdx, 1)
        }
      },
      // 上移/下移
      handleUpDown(name, item, firstIdx, secondIdx) {
        var idx = name == '下移' ? [secondIdx + 1] : [secondIdx - 1]
        this.$set(
          this.query.menu[firstIdx].child,
          secondIdx,
          this.query.menu[firstIdx].child[idx]
        )
        this.$set(this.query.menu[firstIdx].child, idx, item)
      },
      async cancel() {
        await this.delVisitedRoute(this.$route.path)
        this.$router.go(-1)
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.config';
  #{$base}-container {
    min-height: calc(100vh - 60px - 50px - 20px * 2 - 65px);
    padding: 20px;
    background: #fff;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s,
      color 0.1s, font-size 0s;
    #{$base}-title {
      margin: 5px 0;
      font-size: 15px;
      font-weight: bold;
    }
    #{$base}-info {
      margin-bottom: 15px;
      font-size: 13px;
      color: $base-color-text-secondary;
    }
    #{$base}-btn {
      margin: 40px 0 80px 110px;
    }
    .m-r-10 {
      margin-right: 10px;
    }
    .m-b-10 {
      margin-bottom: 10px;
    }
  }
</style>
