import request from '@/utils/request'
// 获取运营位配置
export function getFindLocalConfigGet(params) {
  return request({
    url: '/metatime/config/v1/adminCircle/findLocalConfigGet',
    method: 'get',
    params,
  })
}
// 获取全局来源圈子
export function getSelectCircle(params) {
  return request({
    url: '/metatime/circle/v1/adminCircle/selectCircle',
    method: 'get',
    params,
  })
}
// 最新动态运营位配置
export function getLoadCnmp(params) {
  return request({
    url: '/metatime/circle/v1/adminCircle/loadCnmp',
    method: 'get',
    params,
  })
}
// 本地圈栏目配置
export function getLocalCircleConfig(params) {
  return request({
    url: '/metatime/config/v1/admin/loadLocalCircleConfig',
    method: 'get',
    params,
  })
}
// 来源信息流
export function getCatSelectPage(params) {
  return request({
    url: '/metatime/info/v1/infoAdmin/catSelectPage',
    method: 'get',
    params,
  })
}
// 新建好友动态栏目
export function saveLocalCircleFriend(data) {
  return request({
    url: '/metatime/config/v1/admin/saveLocalCircleFriend',
    method: 'post',
    data,
  })
}
// 新建信息流栏目
export function saveLocalCircleInfo(data) {
  return request({
    url: '/metatime/config/v1/admin/saveLocalCircleInfo',
    method: 'post',
    data,
  })
}
// 删除栏目
export function delLocalCircleCat(data) {
  return request({
    url: '/metatime/config/v1/admin/delLocalCircleCat',
    method: 'post',
    data,
  })
}
// 上移/下移/关闭/启用
export function saveLocalCircleConfig(data) {
  return request({
    url: '/metatime/config/v1/admin/saveLocalCircleConfig',
    method: 'post',
    data,
  })
}
// 本地圈配置保存
export function saveLocal(data) {
  return request({
    url: '/metatime/config/v1/admin/save',
    method: 'post',
    data,
  })
}
// 获取推荐栏目列表
export function getCatShowList(params) {
  return request({
    url: '/metatime/info/v1/infoAdmin/catShowList',
    method: 'get',
    params,
  })
}
// 入口功能配置列表
export function getLoadUserFuncInlet(params) {
  return request({
    url: '/metatime/config/v1/admin/loadUserFuncInlet',
    method: 'get',
    params,
  })
}
// 设置来源列表
export function getSourceList(params) {
  return request({
    url: '/metatime/info/v1/channelAdmin/channelSelectPage',
    method: 'get',
    params,
  })
}
//新建首页多栏目保存
export function indexChannelAdd(data) {
  return request({
    url: '/metatime/config/v1/admin/indexChannelAdd',
    method: 'post',
    data,
  })
}
// 入口功能配置详情
export function getUserFuncByType(params) {
  return request({
    url: '/metatime/config/v1/admin/getUserFuncByType',
    method: 'get',
    params,
  })
}
